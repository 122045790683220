<template>
  <div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mt-3 mb-3">
          <span class="text-muted">Section > </span
          ><span>Suggest New Content</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Suggest new content for the Vault!</h3>

          <textarea
            class="form-control mt-4"
            rows="5"
            v-model="suggestion"
            placeholder="Enter your suggestion here then click submit..."
          ></textarea>
          <button class="btn btn-danger" @click="submitSuggestion()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      suggestion: null,
    };
  },
  methods: {
    submitSuggestion() {
var axios = require('axios');
var FormData = require('form-data');
var data = new FormData();
data.append('message', this.suggestion);
data.append('email', this.$store.state.currentUser.email);
data.append('name', this.$store.state.currentUser.displayName);

var config = {
  method: 'post',
  url: 'https://app.cratehackers.com/version-test/api/1.1/wf/sendvaultemail',
 
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  alert("Your suggestion has been recorded. Thank you!")
  this.suggestion = null
})
.catch(function (error) {
  console.log(error);
});
    },
  },
};
</script>

<style></style>
